//Import all third party code first

//Angular related
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

//Angular Material modules
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

//Primeng modules
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';

//Font Awesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';

//Other custom ngx libs
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from 'ngx-clipboard';
import { PdfViewerModule } from 'ng2-pdf-viewer';

//Now import all our components
import { AppRoutingModule, CustomReuseStrategy } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { ErrorService, ErrorsHandler } from '@app/Global/Errors';
import { BodyPipe, HeaderPipe, SearchFilterPipe, EditLockPipe, EntityFilterPipe, CustomPropertyFilterPipe, DisplayFilterPipe, ControlFilterPipe, SanitizeHtmlPipe, CustomArrayValueFilter } from '@app/Global/CustomPipes';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { LoginModal } from '@app/Components/Loan/LoginModal/LoginModal';
import { LoginModalWrapper } from '@app/Components/Loan/LoginModal/LoginModalWrapper';
import { PasswordReset } from '@app/Components/User/PasswordReset/PasswordReset';
import { Dashboard } from '@app/Components/Dashboard/Dashboard';
import { ChartComponent } from '@app/Components/Dashboard/Charts/CustomChart';
import { ChartDrillThrough } from '@app/Components/Dashboard/Charts/ChartDrillThrough/ChartDrillThrough';
import { UserManagement } from '@app/Components/User/UserManagement/UserManagement';
import { UserProfile } from '@app/Components/User/UserProfile/UserProfile';
import { UserOptions } from '@app/Components/User/UserOptions/UserOptions';
import { RoleManagement } from '@app/Components/User/RoleManagement/RoleManagement';
import { UserInvite } from '@app/Components/User/UserInvite/UserInvite';
import { TwoFactorReset } from '@app/Components/User/TwoFactorReset/TwoFactorReset';
import { Documentation } from '@app/Components/Documentation/Documentation';
import { WorkflowDelegator } from '@app/Components/User/WorkflowDelegator/WorkflowDelegator';
import { AccountManager } from '@app/Components/User/AccountManager/AccountManager';
import { QualityCheckingQueue } from '@app/Components/User/QualityCheckingQueue/QualityCheckingQueue';
import { ConditionSubsequent } from '@app/Components/User/ConditionSubsequent/ConditionSubsequent';
import { ConditionSubsequentDetail } from '@app/Components/User/ConditionSubsequent/ConditionSubsequentDetail/ConditionSubsequentDetail';
import { LoanEntityModify } from '@app/Components/Loan/LoanEntityModify/LoanEntityModify';
import { LoanEntity } from '@app/Components/Loan/LoanEntity/LoanEntity';
import { LoanHeader } from '@app/Components/Loan/LoanHeader/LoanHeader';
import { LoanSearch } from '@app/Components/Loan/LoanSearch/LoanSearch';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { LoanNavBar } from '@app/Components/Loan/LoanNavBar/LoanNavBar';
import { SearchBar } from '@app/Components/Loan/SearchBar/SearchBar';
import { HomeComponent } from '@app/Components/Home/Home';
import { ErrorComponent } from '@app/Components/Error/Error';
import { ForgotPassword } from '@app/Components/Loan/LoginModal/ForgotPassword/ForgotPassword';
import { TwoFactorAuthentication } from '@app/Components/Loan/LoginModal/TwoFactorAuthentication/TwoFactorAuthentication';
import { CustomDateAdapter } from '@app/Global/CustomDateAdapter';
import { LenderTasks } from '@app/Components/User/LenderTasks/LenderTasks';
import { AccountEnquiries } from '@app/Components/User/AccountEnquiries/AccountEnquiries';
import { LenderConfigurations } from '@app/Components/User/LenderConfigurations/LenderConfigurations';
import { LenderConfiguration } from '@app/Components/User/LenderConfigurations/LenderConfiguration/LenderConfiguration';
import { ReassignTask } from '@app/Components/User/WorkflowDelegator/ReassignTask/ReassignTask';
import { DocViewer } from '@app/Components/Loan/DocViewer/DocViewer';
import { StatementGenerator } from '@app/Components/Loan/StatementGenerator/StatementGenerator';
import { DocumentGenerator } from '@app/Components/Loan/DocumentGenerator/DocumentGenerator';
import { PayoutCalculator } from '@app/Components/Loan/PayoutCalculator/PayoutCalculator';
import { ResidentialRepaymentCalculator } from '@app/Components/Loan/ResidentialRepaymentCalculator/ResidentialRepaymentCalculator';
import { Settlements } from '@app/Components/Settlements/Settlements';
import { SettlementConfirmation } from '@app/Components/Settlements/SettlementConfirmation/SettlementConfirmation';
import { SLAReporting } from '@app/Components/SLAReporting/SLAReporting';
import { SLAReportingSummary } from '@app/Components/SLAReporting/SLAReportingSummary/SLAReportingSummary';
import { SLAReportingDrillthrough } from '@app/Components/SLAReporting/SLAReportingDrillthrough/SLAReportingDrillthrough';
import { TaskQualityReview } from '@app/Components/Loan/TaskQualityReview/TaskQualityReview';
import { LeasePayoutCalculator } from '@app/Components/Loan/LeasePayoutCalculator/LeasePayoutCalculator';
import { CreateEmail } from '@app/Components/Loan/CreateEmail/CreateEmail';
import { FinaliseTask } from '@app/Components/Loan/FinaliseTask/FinaliseTask';
import { FormInputDataUnit } from '@app/Components/Loan/FormInputDataUnit/FormInputDataUnit';
import { TaskFollowup } from '@app/Components/Loan/TaskFollowup/TaskFollowup';
import { AccountFee } from '@app/Components/Loan/AccountFee/AccountFee';
import { RichTextEditor } from '@app/Components/User/LenderConfigurations/RichTextEditor/RichTextEditor';
import { AccountSummary } from '@app/Components/Loan/AccountSummary/AccountSummary';

@NgModule({
  declarations: [
    AppComponent,
    SearchBar,
    LoanIndex,
    ErrorComponent,
    LoanSearch,
    HomeComponent,
    LoanNavBar,
    LoanHeader,
    HeaderPipe,
    BodyPipe,
    SearchFilterPipe,
    EditLockPipe,
    LoanEntity,
    ConfirmModal,
    LoginModal,
    LoginModalWrapper,
    PasswordReset,
    ForgotPassword,
    TwoFactorAuthentication,
    EntityFilterPipe,
    CustomPropertyFilterPipe,
    SanitizeHtmlPipe,
    ControlFilterPipe,
    DisplayFilterPipe,
    CustomArrayValueFilter,
    Dashboard,
    ChartComponent,
    UserManagement,
    UserProfile,
    UserOptions,
    RoleManagement,
    UserInvite,
    TwoFactorReset,
    Documentation,
    WorkflowDelegator,
    AccountManager,
    QualityCheckingQueue,
    ConditionSubsequent,
    ConditionSubsequentDetail,
    AccountEnquiries,
    LoanEntityModify,
    LenderTasks,
    LenderConfigurations,
    LenderConfiguration,
    ReassignTask,
    DocViewer,
    StatementGenerator,
    DocumentGenerator,
    PayoutCalculator,
    ResidentialRepaymentCalculator,
    AccountFee,
    ChartDrillThrough,
    Settlements,
    SettlementConfirmation,
    SLAReporting,
    SLAReportingSummary,
    SLAReportingDrillthrough,
    TaskQualityReview,
    LeasePayoutCalculator,
    CreateEmail,
    FinaliseTask,
    FormInputDataUnit,
    TaskFollowup,
    RichTextEditor,
    AccountSummary
  ],
  imports: [
    MatIconModule,
    ClipboardModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatInputModule,
    FontAwesomeModule,
    MatInputModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSelectModule,
    MatAutocompleteModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    PdfViewerModule,
    CalendarModule,
    FileUploadModule,
    OverlayPanelModule,
    InputSwitchModule,
    SelectButtonModule,
    MultiSelectModule,
    DragDropModule,
    MatSidenavModule
  ],
  providers: [
    ErrorService,
    { provide: ErrorHandler, useClass: ErrorsHandler, },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    //Add a provider to override the RouteReuseStrategy, required as per Angular 15. This allows us to enable same url page reloading, and also page caching for the lifetime of the app
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule {
  constructor(faIconLibrary: FaIconLibrary) {
    //Disable inline css and use the imported style in angular.json. This allows us to remove 'unsafe-line' from our CSP
    config.autoAddCss = false;
    faIconLibrary.addIconPacks(fal);
  }
}