import { Component, Input } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { SummaryDetail, SummaryGroup } from '@app/Global/Models/ClientModels';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';

@Component({
  selector: 'AccountSummary',
  templateUrl: './AccountSummary.html',
  styleUrls: ['./AccountSummary.scss'],
  animations: [
    trigger('fadeInSection', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1.0s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class AccountSummary {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions) {
  }

  //Class properties for summary group and detail data
  public SummaryDetailData: SummaryDetail[] = [];
  public SummaryGroupData: SummaryGroup[] = [];

  //Loan index input
  @Input() LoanIndex: LoanIndex;

  //To show/hide spinner
  public ShowSpinner = true;

  //Initialise the summary group and detail data
  public Page_Init(): void {

    //Loop through all the group and summary data from server and push those to class properties
    if (!this.globalFunctions.isEmpty(this.LoanIndex.SelectedAccount.SummaryGroups)) {

      //Looping through summary group
      this.LoanIndex.SelectedAccount.SummaryGroups.forEach(summaryGroup => {

        //Check if we have details linked to the summary data
        if (!this.globalFunctions.isEmpty(summaryGroup.SummaryDetails)) {

          //Now push the group data
          this.SummaryGroupData.push(summaryGroup);

          //Looping through the details linked to the summary group
          summaryGroup.SummaryDetails.forEach(summaryDetail => {

            //Push the detail data
            this.SummaryDetailData.push(summaryDetail);
          });
        }
      });
    }

    //Hide the spinner
    this.ShowSpinner = false;
  }

  //Get the detail data for the target summary group
  public SectionGroupDetail_Get(sectionGroupGUID): SummaryDetail[] {
    return this.SummaryDetailData.filter(x => x.SummaryGroupGUID === sectionGroupGUID);
  }

  //Get bottom line css, hide it for the last item
  public BottomBorderCSS_Get(index): string {
    let cssString = "glb_labelBottomBorderDark";

    //Check if it is the last item in the group
    if (index === this.SummaryGroupData.length - 1) {
      cssString = "";
    }

    return cssString;
  }

  //To navigate to the target entity on clicking the chevron
  public AccountDetails_Navigate(summaryDetail): void {

    //If the target entity is missing, return
    if (this.globalFunctions.isEmpty(summaryDetail.NavTabName)) {
      return;
    }

    //Switch to detail view
    this.LoanIndex.ViewTypeValue = '1';

    //Logic copied from LoanNavBar.NavBarItem_Clicked()
    //Turn off the context bar whenever we switch to another tab
    this.LoanIndex.DataUnit_RemoveSelected();

    //Sync the display of Payout Calculator buttons if we have clicked the transaction nav tab
    if (summaryDetail.NavTabName === 'LoanTransactions') {

      //Target the transaction component
      const transactionComponent = this.LoanIndex.LoanEntityScripts.filter(component => component.EntityName == summaryDetail.NavTabName)[0];

      if (!this.globalFunctions.isEmpty(transactionComponent)) {

        //Sync the display of Payout Calculator buttons
        transactionComponent.PayoutCalculatorButton_Show();
      }
    }

    //Load Entity Data from non preloaded entities
    this.globalFunctions.OnDemandEntities.filter(x => x.IsParent === true).forEach(entity => {
      this.LoanIndex.EntityData_Load('nav-' + summaryDetail.NavTabName + '-tab', entity.EntityName);
    },
    );

    //Update the currently visible nav tab
    this.LoanIndex.CurrentNavItem = 'nav-' + summaryDetail.NavTabName + '-tab';
  }
}