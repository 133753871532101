<div id="PG_AccountSummary" class="glb_formBGColor glb_paddingBottomMD glb_Font glb_Font">
  <div class="glb_customFlexRow glb_boxedPadding">
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="glb_customFlexRow col-12">
        <div class="glb_customFlexRow glb_paddingZeroOffset">
          <div class="glb_paddingZeroOffset">
            <div class="glb_TitleLG glb_TitleLGMobile text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomSM text-white">
              Account Snapshot
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 glb_customFlexRow text-white glb_boxedPaddingXS">
    <div class="col-6 glb_customFlexRow glb_allBordersGreyRounded">
      <div *ngIf="!LoanIndex.CheckAssocs_IsLoaded() && !ShowSpinner" class="col-12 glb_customFlexRow">
        <div *ngFor="let summaryGroup of SummaryGroupData; let i=index" class="glb_boxedPaddingXS col-12 glb_customFlexRow">
          <div class="glb_PaddingHorizontalSM col-12 glb_customFlexRow">
            <div class="col-12 glb_customFlexRow" [ngClass]="BottomBorderCSS_Get(i)">
              <div class="glb_PaddingVerticalMD col-12 glb_customFlexRow">
                <div *ngFor="let summaryDetail of SectionGroupDetail_Get(summaryGroup.GUID)" class="col-12 glb_boxedPaddingXS glb_customFlexRow">
                  <div class="col-12 glb_customFlexRow glb_FontLabelDisplay glb_FontSizeSummary">

                    <div class="col-6 col-lg-4 justify-content-start glb_customFlexRow glb_LabelFontDark">
                      <div>
                        {{summaryDetail.DisplayName}}
                      </div>
                    </div>
                    <div class="col-6 col-lg-8 glb_customFlexRow glb_Font glb_textAlignEnd">
                      <div class="col-11 justify-content-end glb_customFlexRow" [ngClass]="summaryDetail.CSSClass"
                        [innerHTML]="globalFunctions.HTMLUnescape(summaryDetail.Value) | sanitizeHtml">
                        <!-- {{summaryDetail.Value}} -->
                      </div>
                      <div class="col-1 glb_customFlexRow justify-content-end">
                        <fa-icon class="glb_hoverHand" [icon]="['fal', 'chevron-right']" (click)="AccountDetails_Navigate(summaryDetail)"
                          matTooltip="">
                        </fa-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Spinner -->
      <div *ngIf="LoanIndex.CheckAssocs_IsLoaded() || ShowSpinner" class="col-12 glb_boxedPadding glb_customFlexRow">
        <div class="col-12 justify-content-center">
          <div class="text-white glb_customFlexRow justify-content-center">
            <div class="spinner-border glb_spinnerMD"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>