<!-- Sidebar button template - single html that is reused below for the side bar buttons -->
<ng-template #SideBarButtonTemplate let-showSpinner="Spinner_Show" let-id="ID" let-icon="Icon" let-text="DisplayText" let-showText="ShowText"
    let-tooltip="Tooltip" let-isSideBarVisible="IsSideBarVisible" let-clickAction="ClickAction">

    <!-- Standard Sidebar (non spinner - active version)  -->
    <div *ngIf="!showSpinner()" class="glb_customFlexRow col-12 justify-content-center sideBarButtonOuterPadding">
        <div class="glb_customFlexRow col-12 justify-content-center">
            <div *ngIf="isSideBarVisible" id="{{id}}" class="glb_hoverHand glb_customFlexRow col-12 justify-content-center sideBarButton"
                (click)="clickAction()" matTooltip="{{tooltip}}" matTooltipClass="glb_tooltipSM" matTooltipPosition="right">
                <fa-icon class="glb_customFlexRow col-12 justify-content-center sideBarButtonFontAwesome" [icon]="['fal', icon]"></fa-icon>
                <div *ngIf="showText" class="glb_customFlexRow col-12 justify-content-center text-center text-white sideBarButtonText">
                    {{text}}
                </div>
            </div>
        </div>
    </div>

    <!-- This is a spinner version, same styles and content, with a spinner icon in place, and no click method  -->
    <div *ngIf="showSpinner()" class="glb_customFlexRow col-12 justify-content-center sideBarButtonOuterPadding">
        <div class="glb_customFlexRow col-12 justify-content-center">
            <div *ngIf="isSideBarVisible" id="{{id}}_Spinner" class="glb_hoverHand glb_customFlexRow col-12 justify-content-center sideBarButton"
                matTooltip="Please Wait" matTooltipClass="glb_tooltipSM" matToolTipPosition="right">
                <fa-icon class="glb_fastSpin glb_customFlexRow col-12 justify-content-center sideBarButtonFontAwesome"
                    [icon]="['fal', 'compact-disc']"></fa-icon>
                <div *ngIf="showText" class="glb_customFlexRow col-12 justify-content-center text-center text-white sideBarButtonText">
                    {{text}}
                </div>
            </div>
        </div>
    </div>

</ng-template>

<LoanHeader #LoanHeader [loanIndex]="this"></LoanHeader>

<!-- Button that toggles the sidebar buttons (mat-drawer) visibility -->
<div class="sideNavToggleButton">
    <ng-container *ngTemplateOutlet="SideBarButtonTemplate;context:SidebarToggleVisibility"></ng-container>
</div>

<!-- Mat sidebar breakdown - this is the parent container which contains both the 'mat-drawer' and the content -->
<mat-drawer-container class="sideNavContainer" autosize>

    <!-- Sidebar drawer content, which contains its buttons. opened=true means it will default to an opened state -->
    <mat-drawer #SB_Drawer class="sideNavDrawer" mode="side" opened="true">

        <div>
            <!-- Loop through and show the sidebar buttons based on the config array that was prepared oninit -->
            <div *ngFor="let sidebarButton of SideBarButtons">
                <ng-container *ngTemplateOutlet="SideBarButtonTemplate;context:sidebarButton"></ng-container>
            </div>
        </div>

    </mat-drawer>

    <!-- Main content (to the right of sidebar) goes here  -->

    <!-- Selector div to show summary or detail section -->
    <div *ngIf="ShowViewTypeOption === true" class="glb_customFlexRow glb_paddingBottomMD">
        <!-- <div class="glb_Font glb_customFlexRow text-white align-items-center"></div> -->
        <p-selectButton class="p-customChooseOptions" [options]="ViewTypeOptions" [ngModel]="ViewTypeValue" [multiple]="false" optionLabel="label"
            optionValue="value" [allowEmpty]="false" (onChange)="ViewTypeOption_Click($event)"></p-selectButton>
    </div>

    <!-- Account Summary -->
    <div [ngClass]="DisplayCSS_Get(true)">
        <div class="glb_PaddingVerticalSM">
            <div>
                <AccountSummary #AccountSummary [LoanIndex]="this"></AccountSummary>
            </div>
        </div>
    </div>

    <!-- Account Details -->
    <div [ngClass]="DisplayCSS_Get(false)">

        <!-- Pass a copy of loanIndex down so that the nav tabs can trigger updates, when needed (e.g. loading accruals) -->
        <LoanNavBar [LoanIndex]="this"></LoanNavBar>

        <!-- Entities are here -->
        <div>

            <!-- Transactions tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanTransactions')">
                <div [ngClass]="ShowCurrentNavItem('LoanTransactions')" id="nav-LoanTransactions">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanTransactions'" [ColumnsInBody]="8" [ShowHeader]="false"
                        [ColumnsInHeader]="1" [WrapOddRowsHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [TableHeaders]="Entity_GetHeaders('LoanTransactions')" [ShowTableHeaders]="true" [PaginatorItemsPerPage]="50"
                        [ShowCreateNewEntityButton]=false>
                    </LoanEntity>
                </div>
            </div>

            <!-- Interest Accruals tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('InterestAccruals')">
                <div [ngClass]="ShowCurrentNavItem('InterestAccruals')" id="nav-InterestAccruals">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'InterestAccruals'" [ColumnsInBody]="8" [ShowHeader]="false"
                        [ColumnsInHeader]="1" [WrapOddRowsHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [TableHeaders]="Entity_GetHeaders('InterestAccruals')" [ShowTableHeaders]="true" [PaginatorItemsPerPage]="50">
                    </LoanEntity>
                </div>
            </div>

            <!-- Loan Fee tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanFeeSetup')">
                <div [ngClass]="ShowCurrentNavItem('LoanFeeSetup')" id="nav-LoanFeeSetup">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanFeeSetup'" [ColumnsInBody]="8" [ShowHeader]="false"
                        [ColumnsInHeader]="1" [WrapOddRowsHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [TableHeaders]="Entity_GetHeaders('LoanFeeSetup')" [ShowTableHeaders]="true" [PaginatorItemsPerPage]="10">
                    </LoanEntity>
                </div>
            </div>

            <!-- Associations tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanAssociations')">
                <div [ngClass]="ShowCurrentNavItem('LoanAssociations')" id="nav-LoanAssociations">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanAssociations'" [ColumnsInBody]="1" [ShowHeader]="true"
                        [ColumnsInHeader]="2" [WrapOddRowsHeader]="true" [WrapOddRows]="false" [EntityDict]="EntityDict" [ColumnsInTab]="2"
                        [CombineLabelAndData]="true">
                    </LoanEntity>
                </div>
            </div>

            <!-- Pending tasks tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('PendingTasks')">
                <div [ngClass]="ShowCurrentNavItem('PendingTasks')" id="nav-PendingTasks">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'PendingTasks'" [ShowHeader]="false" [ColumnsInHeader]="1"
                        [WrapOddRowsHeader]="false" [ColumnsInBody]="6" [WrapOddRows]="true" [CombineLabelAndData]="false" [EntityDict]="EntityDict"
                        [TableHeaders]="Entity_GetHeaders('PendingTasks')" [ShowTableHeaders]="true" [ColumnsInTab]="1">
                    </LoanEntity>
                </div>
            </div>

            <!-- Contract Payments tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanPayments')">
                <div [ngClass]="ShowCurrentNavItem('LoanPayments')" id="nav-LoanPayments">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanPayments'" [ShowHeader]="false" [ColumnsInHeader]="1"
                        [WrapOddRowsHeader]="false" [ColumnsInBody]="8" [WrapOddRows]="false" [CombineLabelAndData]="false" [EntityDict]="EntityDict"
                        [TableHeaders]="Entity_GetHeaders('LoanPayments')" [ShowTableHeaders]="true" [ColumnsInTab]="1">
                    </LoanEntity>
                </div>
            </div>

            <!-- Loan Transfers tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanTransfers')">
                <div [ngClass]="ShowCurrentNavItem('LoanTransfers')" id="nav-LoanTransfers">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanTransfers'" [ShowHeader]="true" [ColumnsInHeader]="1"
                        [WrapOddRowsHeader]="true" [ColumnsInBody]="2" [WrapOddRows]="false" [CombineLabelAndData]="false" [EntityDict]="EntityDict"
                        [ColumnsInTab]="1">
                    </LoanEntity>
                </div>
            </div>

            <!-- Bank Details tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('BankDetails')">
                <div [ngClass]="ShowCurrentNavItem('BankDetails')" id="nav-BankDetails">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'BankDetails'" [ShowHeader]="true" [ColumnsInHeader]="1"
                        [WrapOddRowsHeader]="true" [ColumnsInBody]="2" [WrapOddRows]="false" [CombineLabelAndData]="false" [EntityDict]="EntityDict"
                        [ColumnsInTab]="1">
                    </LoanEntity>
                </div>
            </div>

            <!-- Account Details tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('AccountDetails')">
                <div [ngClass]="ShowCurrentNavItem('AccountDetails')" id="nav-AccountDetails">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'AccountDetails'" [ShowHeader]="false" [WrapOddRowsHeader]="true"
                        [ColumnsInBody]="2" [WrapOddRows]="false" [CombineLabelAndData]="false" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [ShowSearchFilterBox]="false" [ShowDownloadButton]="false" [ShowCreateNewEntityButton]=false>
                    </LoanEntity>

                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'AccountSubDetails'" [ShowHeader]="false" [WrapOddRowsHeader]="true"
                        [ColumnsInBody]="2" [WrapOddRows]="false" [CombineLabelAndData]="false" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [ShowSearchFilterBox]="false" [ShowDownloadButton]="false" [ShowCreateNewEntityButton]=false>
                    </LoanEntity>

                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'ForeignKeys'" [ShowHeader]="false" [WrapOddRows]="true"
                        [EntityDict]="EntityDict" [ColumnsInTab]="2" [ShowSearchFilterBox]="false" [ShowDownloadButton]="false">
                    </LoanEntity>
                </div>
            </div>

            <!-- Loan Tasks tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanTasks')">
                <div [ngClass]="ShowCurrentNavItem('LoanTasks')" id="nav-LoanTasks">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanTasks'" [ShowHeader]="true" [ColumnsInHeader]="2"
                        [WrapOddRowsHeader]="true" [ColumnsInBody]="1" [WrapOddRows]="false" [CombineLabelAndData]="true" [EntityDict]="EntityDict"
                        [ColumnsInTab]="2">
                    </LoanEntity>
                </div>
            </div>

            <!-- Custom Value Fields tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('CustomFields')">
                <div [ngClass]="ShowCurrentNavItem('CustomFields')" id="nav-CustomFields">
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('CustomFieldStrings').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'CustomFieldStrings'" [ShowHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="2"
                        [ShowSearchFilterBox]="false" [ShowDownloadButton]="true">
                    </LoanEntity>
                    <br>
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('CustomFieldNumeric').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'CustomFieldNumeric'" [ShowHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="2"
                        [ShowSearchFilterBox]="false" [ShowDownloadButton]="true">
                    </LoanEntity>
                    <br>
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('CustomFieldDates').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'CustomFieldDates'" [ShowHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="2"
                        [ShowSearchFilterBox]="false" [ShowDownloadButton]="true">
                    </LoanEntity>
                    <br>
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('CustomFieldCurrency').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'CustomFieldCurrency'" [ShowHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="2"
                        [ShowSearchFilterBox]="false" [ShowDownloadButton]="true">
                    </LoanEntity>
                </div>
            </div>

            <!-- Loan Dates tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanDates')">
                <div [ngClass]="ShowCurrentNavItem('LoanDates')" id="nav-LoanDates">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanDates'" [ShowHeader]="false" [WrapOddRows]="true"
                        [EntityDict]="EntityDict" [ColumnsInTab]="2">
                    </LoanEntity>
                </div>
            </div>

            <!-- Loan Balances tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanBalances')">
                <div [ngClass]="ShowCurrentNavItem('LoanBalances')" id="nav-LoanBalances">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanBalances'" [ShowHeader]="false" [WrapOddRows]="true"
                        [EntityDict]="EntityDict" [ColumnsInTab]="2">
                    </LoanEntity>
                </div>
            </div>

            <!-- Loan Arrears tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('Arrears')">
                <div [ngClass]="ShowCurrentNavItem('Arrears')" id="nav-Arrears">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'Arrears'" [ShowHeader]="true" [ColumnsInHeader]="2"
                        [WrapOddRowsHeader]="true" [WrapOddRows]="false" [CombineLabelAndData]="true" [ColumnsInBody]="1" [EntityDict]="EntityDict"
                        [ColumnsInTab]="2" [ShowSearchFilterBox]="false" [ShowCreateNewEntityButton]=false>
                    </LoanEntity>

                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'ArrearsBreakdown'" [ShowHeader]="true" [ColumnsInHeader]="2"
                        [WrapOddRowsHeader]="true" [WrapOddRows]="true" [CombineLabelAndData]="false" [ColumnsInBody]="2" [EntityDict]="EntityDict"
                        [ColumnsInTab]="2" [ShowSearchFilterBox]="false" [ShowCreateNewEntityButton]=false>
                    </LoanEntity>
                </div>
            </div>

            <!-- Loan Rates tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanRates')">
                <div [ngClass]="ShowCurrentNavItem('LoanRates')" id="nav-LoanRates">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanRates'" [ColumnsInBody]="8" [ShowHeader]="false"
                        [ColumnsInHeader]="1" [WrapOddRowsHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [TableHeaders]="Entity_GetHeaders('LoanRates')" [ShowTableHeaders]="true">
                    </LoanEntity>
                </div>
            </div>

            <!--  Securities tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('Securities')">
                <div [ngClass]="ShowCurrentNavItem('Securities')" id="nav-Securities">
                    <!--  Residential Securities -->
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('LoanSecurities').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'LoanSecurities'" [ShowHeader]="true" [ColumnsInHeader]="2" [WrapOddRowsHeader]="true" [WrapOddRows]="false"
                        [EntityDict]="EntityDict" [ColumnsInTab]="1">
                    </LoanEntity>

                    <!--  Vehicle Securities -->
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('LoanVehicleSecurities').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'LoanVehicleSecurities'" [ShowHeader]="true" [ColumnsInHeader]="2" [WrapOddRowsHeader]="true"
                        [WrapOddRows]="false" [EntityDict]="EntityDict" [ColumnsInTab]="1">
                    </LoanEntity>
                </div>
            </div>

            <!-- Facility tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('Facility')">
                <div [ngClass]="ShowCurrentNavItem('Facility')" id="nav-Facility">
                    <!--  FacilityBalances -->
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('FacilityBalances').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'FacilityBalances'" [ShowHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="2"
                        [ShowSearchFilterBox]="false" [ShowCreateNewEntityButton]=false [AddBottomPadding]=true>
                    </LoanEntity>

                    <!--  FacilityLVR -->
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('FacilityLVR').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'FacilityLVR'" [ShowHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="2"
                        [ShowSearchFilterBox]="false" [ShowCreateNewEntityButton]=false [AddBottomPadding]=true>
                    </LoanEntity>

                    <!-- Linked Accounts -->
                    <LoanEntity *ngIf="GetEntityClaimNonFriendlyName('LinkedAccounts').Read" #LoanEntities [loanIndex]="this"
                        [EntityName]="'LinkedAccounts'" [ShowHeader]="false" [WrapOddRowsHeader]="false" [ColumnsInHeader]="1" [ColumnsInBody]="2"
                        [WrapOddRows]="true" [CombineLabelAndData]="false" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [TableHeaders]="Entity_GetHeaders('LinkedAccounts')" [ShowTableHeaders]="true" [PaginatorItemsPerPage]="50"
                        [ShowSearchFilterBox]="true" [ShowCreateNewEntityButton]=false>
                    </LoanEntity>
                </div>
            </div>

            <!--  Documents tab - tabular style view -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanDocuments')">
                <div [ngClass]="ShowCurrentNavItem('LoanDocuments')" id="nav-LoanDocuments">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanDocuments'" [ColumnsInBody]="4" [ShowHeader]="false"
                        [ColumnsInHeader]="1" [WrapOddRowsHeader]="false" [WrapOddRows]="true" [EntityDict]="EntityDict" [ColumnsInTab]="1"
                        [TableHeaders]="Entity_GetHeaders('LoanDocuments')" [ShowTableHeaders]="true" [PaginatorItemsPerPage]="50">
                    </LoanEntity>
                </div>
            </div>

            <!--  Notes tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('LoanNotes')">
                <div [ngClass]="ShowCurrentNavItem('LoanNotes')" id="nav-LoanNotes">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'LoanNotes'" [WrapOddRows]="true" [ShowHeader]="false"
                        [WrapOddRowsHeader]="false" [ColumnsInBody]="5" [ColumnsInHeader]="1" [EntityDict]="EntityDict"
                        [TableHeaders]="Entity_GetHeaders('LoanNotes')" [ShowTableHeaders]="true" [ColumnsInTab]="1" [PaginatorItemsPerPage]="50">
                    </LoanEntity>
                </div>
            </div>

            <!-- Calculated Fields tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('CalculatedFields')">
                <div [ngClass]="ShowCurrentNavItem('CalculatedFields')" id="nav-CalculatedFields">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'CalculatedFields'" [ShowHeader]="false" [WrapOddRows]="true"
                        [EntityDict]="EntityDict" [ColumnsInTab]="2">
                    </LoanEntity>
                </div>
            </div>

            <!-- Data Fields tab -->
            <div class="glb_entityBackground glb_boxedPadding" [ngClass]="CurrentNavItemForceRemoval('AccountDataFields')">
                <div [ngClass]="ShowCurrentNavItem('AccountDataFields')" id="nav-AccountDataFields">
                    <LoanEntity #LoanEntities [loanIndex]="this" [EntityName]="'AccountDataFields'" [ShowHeader]="false" [WrapOddRows]="true"
                        [EntityDict]="EntityDict" [ColumnsInTab]="2">
                    </LoanEntity>
                </div>
            </div>

        </div>

    </div>
</mat-drawer-container>